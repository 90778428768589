var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c("span", { staticClass: "text-center d-block f17" }, [
        _vm._v(_vm._s(_vm.exam.examName)),
      ]),
      _c(
        "v-card",
        {
          staticClass: "d-flex flex-column align-items-center my-4 p-2",
          attrs: { rounded: "lg", flat: "", color: "grey lighten-4" },
        },
        [
          _c(
            "v-avatar",
            { staticClass: "my-2", attrs: { size: "96", rounded: "lg" } },
            [
              _c("v-img", {
                attrs: {
                  "lazy-src": require("@/assets/images/profile-circle.png"),
                  src:
                    _vm.exam.profilePicture != null &&
                    _vm.exam.profilePicture != ""
                      ? _vm.exam.profilePicture
                      : require("@/assets/images/profile.jpg"),
                },
              }),
            ],
            1
          ),
          _c("span", { staticClass: "my-1 f14 font-weight-bold" }, [
            _vm._v(
              " " + _vm._s(_vm.exam.firstName + " " + _vm.exam.lastName) + " "
            ),
          ]),
          _c("span", { staticClass: "my-1 f13 grey--text text--darken-1" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("Age:")) +
                " " +
                _vm._s(_vm.exam.age) +
                " " +
                _vm._s(_vm.$t("Year")) +
                " "
            ),
          ]),
          _c("span", { staticClass: "my-1 f13 grey--text text--darken-1" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("Date of prescription:")) +
                " " +
                _vm._s(
                  _vm._f("moment")(_vm.exam.createDate, "dddd , jD jMMMM jYYYY")
                ) +
                " "
            ),
          ]),
          _c("span", { staticClass: "my-1 f13 grey--text text--darken-1" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("Date of completion:")) +
                " " +
                _vm._s(
                  _vm._f("moment")(_vm.exam.updateDate, "dddd , jD jMMMM jYYYY")
                ) +
                " "
            ),
          ]),
        ],
        1
      ),
      _c("span", { staticClass: "d-block mt-4 f16 font-weight-bold" }, [
        _vm._v(_vm._s(_vm.$t("Exam scale"))),
      ]),
      _vm.examResult && _vm.examResult.length > 0
        ? _c(
            "div",
            _vm._l(_vm.examResult, function (scale, index) {
              return _c(
                "v-card",
                {
                  key: index,
                  staticClass: "my-2 py-4 px-6 d-flex justify-content-between",
                  attrs: { flat: "", color: "light-blue lighten-5" },
                },
                [
                  _c("span", { staticClass: "f14 font-weight-bold" }, [
                    _vm._v(_vm._s(scale.title)),
                  ]),
                  _c(
                    "span",
                    { staticClass: "f14 primary--text font-weight-bold" },
                    [_vm._v(_vm._s(scale.score))]
                  ),
                ]
              )
            }),
            1
          )
        : _c("span", { staticClass: "text-center f16 d-block mt-8" }, [
            _vm._v(" " + _vm._s(_vm.$t("No results found")) + " "),
          ]),
      _c("span", { staticClass: "d-block mt-4 f16 font-weight-bold" }, [
        _vm._v(_vm._s(_vm.$t("Exam guide"))),
      ]),
      _c("p", {
        staticClass: "f14",
        domProps: { innerHTML: _vm._s(_vm.exam.drDescription) },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }